.App {
  max-width: 800px;
  margin: auto;
}

p {
  white-space: pre-line;
}


.revision {
  color: grey;
  font-size: 80%
}

.detail{
  color: grey;
  font-size: 80%;
}